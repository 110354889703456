body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-x: hidden;
  background: "#FFFFFF";
}

body, html {
  height: initial;
}

* {
  box-sizing: border-box;
}

#register,
#login {
  min-height: 100%;
  height: 100%;
}

/* modal background */
.ui.page.dimmer{
  background-color: rgba(34, 34, 34, 0.5);
}

.ui.dimmer {
  z-index: 99999999999 !important;
}

.ui.form .error.message, .ui.form .success.message, .ui.form .warning.message {
  display: block;
}

.ui.form .inline.field:not(.wide) .ui.input, .ui.form .inline.fields .field:not(.wide) .ui.input {
  width: 100%;
}

.ui.form .inline.fields {
  align-items: baseline;
}

p {
  word-break: break-word;
}

.slick-dots li button:before {
  font-size: 16px;
}

.slick-dots li.slick-active button:before {
  color: #34a650;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
  color: #34a650;
}

ul {
  display: block;
  margin: 0;
  padding-left: 20px;
}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  display: flex!important;
}

.ui.modal {
  margin-top: 0!important;
  position: initial!important;
}

.ui.form .field {
  clear: both;
  margin: 0 0 2em;
}

h1, h2, h3, h4, h5 {
  font-family: "Open Sans" !important;
}

.gridRowMargin {
  margin: 14px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.reservationItem {
  height: 32px!important;
  background: #E6E6EE!important;
  border: 0!important;
  border-left: 2px solid #647899!important;
  color: #71819A!important;
  font-family: 'Open Sans'!important;
  font-size: 10px!important;
  font-style: italic!important;
  font-weight: bold!important;
  letter-spacing: 0!important;
  line-height: 10px!important;
  padding: 11px 2px!important;
}

.selectedReservation {
  height: 32px!important;
  background: #DDDDE6!important;
  border: 0!important;
  border-left: 2px solid #647899!important;
  color: #71819A!important;
  font-family: 'Open Sans'!important;
  font-size: 10px!important;
  font-style: italic!important;
  font-weight: bold!important;
  letter-spacing: 0!important;
  line-height: 10px!important;
  padding: 11px 2px!important;
}